import React from 'react'
import { ChatBotConfig } from './config'

export enum ChatBotNode {
  START = 'START',
  MENU = 'MENU',
  FILL_CPF = 'FILL_CPF',
  FILL_CNPJ = 'FILL_CNPJ',
  SEND_TEXT = 'SEND_TEXT',
  FILL_NAME = 'FILL_NAME',
  FILL_EMAIL = 'FILL_EMAIL',
  SEND_ATTACHMENT = 'SEND_ATTACHMENT',
  REDIRECT_SECTOR = 'REDIRECT_SECTOR',
  REDIRECT_ATTENDANT = 'REDIRECT_ATTENDANT',
  FINISH_CONVERSATION = 'FINISH_CONVERSATION',

  SET_PERSON_TAG = 'SET_PERSON_TAG',
  SET_CONVERSATION_TAG = 'SET_CONVERSATION_TAG',
}

export const ChatBotSidebar: React.FC = () => {
  const onDragStart = (event: React.DragEvent<HTMLDivElement>, nodeType: ChatBotNode) => {
    event.dataTransfer.setData('application/reactflow', nodeType)
    event.dataTransfer.effectAllowed = 'move'
  }

  return (
    <aside>
      <ChatBotConfig />
      <div draggable className="node-selector" onDragStart={(event) => onDragStart(event, ChatBotNode.MENU)}>
        <i className="fa fa-comments" /> Menu
      </div>

      <div className='node-label'>
        <div className="name">Ações</div>
        <div className="line" />
      </div>
      <div draggable className="node-selector" onDragStart={(event) => onDragStart(event, ChatBotNode.REDIRECT_SECTOR)}>
        <i className="fa fa-arrow-right" /> Redireciona setor
      </div>
      <div
        draggable
        className="node-selector"
        onDragStart={(event) => onDragStart(event, ChatBotNode.REDIRECT_ATTENDANT)}
      >
        <i className="fa fa-arrow-right" /> Redireciona atendente
      </div>
      <div
        draggable className="node-selector"
        onDragStart={(event) => onDragStart(event, ChatBotNode.FINISH_CONVERSATION)}
      >
        <i className="fa fa-times" /> Finaliza atendimento
      </div>
      <div
        draggable className="node-selector"
        onDragStart={(event) => onDragStart(event, ChatBotNode.SET_CONVERSATION_TAG)}
      >
        <i className="fa fa-tags" /> Etiqueta atendimento
      </div>
      <div
        draggable className="node-selector"
        onDragStart={(event) => onDragStart(event, ChatBotNode.SET_PERSON_TAG)}
      >
        <i className="fa fa-tags" /> Etiqueta contato
      </div>

      <div className='node-label'>
        <div className="name">Envios</div>
        <div className="line" />
      </div>
      <div draggable className="node-selector" onDragStart={(event) => onDragStart(event, ChatBotNode.SEND_TEXT)}>
        <i className="fa fa-comment" /> Envia texto
      </div>
      <div draggable className="node-selector" onDragStart={(event) => onDragStart(event, ChatBotNode.SEND_ATTACHMENT)}>
        <i className="fa fa-paperclip" /> Envia anexo
      </div>

      <div className='node-label'>
        <div className="name">Preenche dados</div>
        <div className="line" />
      </div>
      <div draggable className="node-selector" onDragStart={(event) => onDragStart(event, ChatBotNode.FILL_NAME)}>
        <i className="fa fa-keyboard" /> Preenche nome
      </div>
      <div draggable className="node-selector" onDragStart={(event) => onDragStart(event, ChatBotNode.FILL_EMAIL)}>
        <i className="fa fa-keyboard" /> Preenche e-mail
      </div>
      <div draggable className="node-selector" onDragStart={(event) => onDragStart(event, ChatBotNode.FILL_CPF)}>
        <i className="fa fa-keyboard" /> Preenche CPF
      </div>
      <div draggable className="node-selector" onDragStart={(event) => onDragStart(event, ChatBotNode.FILL_CNPJ)}>
        <i className="fa fa-keyboard" /> Preenche CNPJ
      </div>
    </aside>
  )
}
